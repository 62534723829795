/*
| ===================================================
| This File is for handling payment
| ===================================================
*/
<template>
	<v-row no-gutters class="pa-10">
			<v-col cols="12" class="pt-10"><h2>Acquisto Effettuato</h2></v-col>
			<v-col cols="12" class="pb-10">
				Grazie per aver effettuato il pagamento, a breve riceverai la tua polizza.
			</v-col>
	</v-row>
</template>

<script>

export default {
	name: "Payment",
	mounted() {
		this.$store.dispatch('updateRichiesta', {
			payment_id: this.$route.params.id
		})
	}
}
</script>

<style scoped>

</style>
